import { NoResultsEmoji } from "../../../helpers/clientSide/emojis";
import { mapGenericContent } from "../../../helpers/clientSide/replaceTagsWithFunctionComponents/mappings/htmlTagsMappedToReactComponents";
import { replaceHtmlWithFunctionComponent } from "../../../helpers/clientSide/replaceTagsWithFunctionComponents/replaceHtmlWithFunctionComponent";
import { DefaultPageProps } from "../../../interfaces/WordPress/DefaultPageInterfaces";
import RVOContainer from "../../02-molecules/RVOContainer/RVOContainer";
import RVOGenericHeroSection from "../../03-organisms/RVOGenericHeroSection/RVOGenericHeroSection";
import { useRVODefaultTemplateStyles } from "./RVODefaultTemplateStyles";

const RVODefaultTemplate = (props: DefaultPageProps): JSX.Element => {
  const classes = useRVODefaultTemplateStyles();

  const title = props.page?.title || "";
  const content = props.page?.content || "";

  return (
    <>
      <RVOGenericHeroSection title={title} />

      <RVOContainer paddingBottom={true} className={classes.description}>
        {replaceHtmlWithFunctionComponent(content, mapGenericContent) || (
          <NoResultsEmoji />
        )}
      </RVOContainer>
    </>
  );
};

export default RVODefaultTemplate;
