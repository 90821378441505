import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useRVODefaultTemplateStyles = makeStyles((theme: Theme) =>
  createStyles({
    description: {
      "& > *": {
        padding: `${theme.spacing(2)} ${theme.spacing(16)}`,
        [theme.breakpoints.only("md")]: {
          padding: `${theme.spacing(2)} ${theme.spacing(10)}`,
        },
        [theme.breakpoints.only("sm")]: {
          padding: `${theme.spacing(2)} ${theme.spacing(5)}`,
        },
        [theme.breakpoints.only("xs")]: {
          padding: `${theme.spacing(2)} ${theme.spacing(2)}`,
        },
      },
    },
  })
);
