import { Seo } from "gatsby-plugin-wpgraphql-seo";
import { useSinglePageByDatabaseId } from "../../queries/wpPage/useSinglePageByDatabaseId";
import RVODefaultTemplate from "../04-templates/RVODefaultTemplate/RVODefaultTemplate";

export const AVVPage = (): JSX.Element => {
  const avvPage = useSinglePageByDatabaseId(3109);

  return (
    <>
      <Seo post={avvPage} />

      <RVODefaultTemplate page={avvPage} />
    </>
  );
};

export default AVVPage;
