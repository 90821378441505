import { graphql, useStaticQuery } from "gatsby";

export const useAllPages =
  (): GatsbyTypes.AllPagesQuery["allWpPage"]["nodes"] => {
    const pages = useStaticQuery<GatsbyTypes.AllPagesQuery>(graphql`
      query AllPages {
        allWpPage {
          nodes {
            ...WpPageFields
          }
        }
      }
    `);

    return pages.allWpPage.nodes;
  };
