import { useAllPages } from "./useAllPages";

export const useSinglePageByDatabaseId = (
  databaseId: GatsbyTypes.Maybe<number>
): GatsbyTypes.WpPageFieldsFragment | undefined => {
  const allPages = useAllPages();

  const singlePage = allPages.find((page) => page.databaseId === databaseId);

  return singlePage;
};
